

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";

@Component
export default class ManageEmails extends Vue {
  public emailList = [];
  public jsonData: any = [];
  public currentTemplate: any = {};
  public msg: any = null;
  $router: any;
  public allDataTemplate = [];
  public isDownload = 'No';
  public async saveFile() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/email-template/getEmailTemplates/${this.isDownload}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.emailList = response.data;
        this.jsonData = JSON.stringify(this.emailList);
        const fileUrl = window.URL.createObjectURL(
          new Blob([JSON.stringify(this.emailList)])
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;

        fileLink.setAttribute("download", "emailTemplate.json");
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async getEmailTemplates() {
    this.$store.state.tmpcoLoader = true;
    try {
      this.isDownload = 'No';
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/email-template/getEmailTemplates/${this.isDownload}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.emailList = response.data;
        this.jsonData = JSON.stringify(this.emailList);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public get123(str) {
    str = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
    return str;
  }

  public goTo(data) {
    this.$router.push({ path: "/edit-template", query: data });
  }

  public addTemplates(data) {
    this.$router.push({ path: "/add-template", query: data });
  }
  public dataShow(data) {
    this.$router.push({ path: "/data-show", query: data });
  }
  async mounted() {
    await this.getEmailTemplates();
  }
}
